@charset "UTF-8";
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

body, * {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "游ゴシック Medium", YuGothic, YuGothicM, メイリオ, Meiryo, sans-serif;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch; }

/* -------------------------------------------- */
#wrapper {
  background-image: url("../images/main_back.png"), url("../images/copy_right.png");
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* 撮影 */
  /* 認証エラー */ }
  #wrapper .btn {
    cursor: pointer; }
  #wrapper article.notavailable {
    display: none !important; }
  #wrapper #shot_image_panel {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    /* 撮影したときの表示 */ }
    #wrapper #shot_image_panel.show {
      opacity: 1;
      pointer-events: all; }
    #wrapper #shot_image_panel .btn {
      cursor: pointer; }
    #wrapper #shot_image_panel .inner {
      width: 100vw;
      height: 100%;
      overflow-y: scroll;
      transform: translateZ(0);
      /* 画像エリア */
      /* ボタンエリア */ }
      #wrapper #shot_image_panel .inner .image_area {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5vw; }
        #wrapper #shot_image_panel .inner .image_area .return_btn {
          margin-bottom: 2vw; }
          #wrapper #shot_image_panel .inner .image_area .return_btn img {
            width: 70vw; }
        #wrapper #shot_image_panel .inner .image_area .image_inner {
          width: 100vw;
          height: 177.5vw;
          background-color: rgba(255, 255, 255, 0.6);
          position: relative;
          transform: scale(0.8);
          margin-top: -17.75vw;
          margin-bottom: -17.75vw; }
          #wrapper #shot_image_panel .inner .image_area .image_inner .video_wrap {
            width: 100vw;
            height: 177.5vw;
            position: absolute;
            top: 0;
            left: 0; }
            #wrapper #shot_image_panel .inner .image_area .image_inner .video_wrap video {
              width: 100vw;
              height: 177.5vw; }
            #wrapper #shot_image_panel .inner .image_area .image_inner .video_wrap .flame_img {
              width: 100vw;
              height: 177.5vw;
              position: absolute;
              top: 0;
              left: 0; }
          #wrapper #shot_image_panel .inner .image_area .image_inner .canvas_wrap {
            width: 100vw;
            height: 177.5vw;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0; }
            #wrapper #shot_image_panel .inner .image_area .image_inner .canvas_wrap canvas {
              width: 100vw;
              height: 177.5vw; }
          #wrapper #shot_image_panel .inner .image_area .image_inner .img_wrap {
            width: 100vw;
            height: 177.5vw;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0; }
            #wrapper #shot_image_panel .inner .image_area .image_inner .img_wrap img {
              width: 100vw;
              height: 177.5vw; }
      #wrapper #shot_image_panel .inner .btn_area {
        position: relative;
        margin-top: 5vw;
        margin-bottom: 5vw;
        display: flex;
        justify-content: center;
        align-items: center; }
        #wrapper #shot_image_panel .inner .btn_area .btn {
          cursor: pointer; }
        #wrapper #shot_image_panel .inner .btn_area .shot_btn_area {
          width: 80vw;
          display: flex;
          justify-content: space-between; }
          #wrapper #shot_image_panel .inner .btn_area .shot_btn_area .btn img {
            height: 14vw; }
        #wrapper #shot_image_panel .inner .btn_area .img_btn_area {
          display: none; }
    #wrapper #shot_image_panel.photo_mode .image_area .inner .img_wrap {
      opacity: 1; }
    #wrapper #shot_image_panel.photo_mode .btn_area .shot_btn_area {
      display: none; }
    #wrapper #shot_image_panel.photo_mode .btn_area .img_btn_area {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      width: 90vw; }
      #wrapper #shot_image_panel.photo_mode .btn_area .img_btn_area .btn img {
        width: 70vw; }
  #wrapper #auth_err_panel {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s; }
    #wrapper #auth_err_panel.show {
      opacity: 1;
      pointer-events: all; }
    #wrapper #auth_err_panel .inner {
      background-color: rgba(255, 255, 255, 0.8);
      width: 90%;
      min-height: 30%;
      box-sizing: border-box;
      padding: 30px;
      border-radius: 10px; }
      #wrapper #auth_err_panel .inner h1 {
        font-size: 20px;
        text-align: center; }
      #wrapper #auth_err_panel .inner p {
        font-size: 16px;
        text-align: center;
        margin-top: 20px; }
